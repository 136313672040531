// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-comparison-adoric-js": () => import("./../../../src/pages/comparison/adoric.js" /* webpackChunkName: "component---src-pages-comparison-adoric-js" */),
  "component---src-pages-comparison-hello-bar-js": () => import("./../../../src/pages/comparison/hello-bar.js" /* webpackChunkName: "component---src-pages-comparison-hello-bar-js" */),
  "component---src-pages-comparison-index-js": () => import("./../../../src/pages/comparison/index.js" /* webpackChunkName: "component---src-pages-comparison-index-js" */),
  "component---src-pages-comparison-mailmunch-js": () => import("./../../../src/pages/comparison/mailmunch.js" /* webpackChunkName: "component---src-pages-comparison-mailmunch-js" */),
  "component---src-pages-comparison-ninja-popup-js": () => import("./../../../src/pages/comparison/ninja-popup.js" /* webpackChunkName: "component---src-pages-comparison-ninja-popup-js" */),
  "component---src-pages-comparison-optimonk-js": () => import("./../../../src/pages/comparison/optimonk.js" /* webpackChunkName: "component---src-pages-comparison-optimonk-js" */),
  "component---src-pages-comparison-optinmonster-js": () => import("./../../../src/pages/comparison/optinmonster.js" /* webpackChunkName: "component---src-pages-comparison-optinmonster-js" */),
  "component---src-pages-comparison-poptin-js": () => import("./../../../src/pages/comparison/poptin.js" /* webpackChunkName: "component---src-pages-comparison-poptin-js" */),
  "component---src-pages-comparison-privy-js": () => import("./../../../src/pages/comparison/privy.js" /* webpackChunkName: "component---src-pages-comparison-privy-js" */),
  "component---src-pages-comparison-sleeknote-js": () => import("./../../../src/pages/comparison/sleeknote.js" /* webpackChunkName: "component---src-pages-comparison-sleeknote-js" */),
  "component---src-pages-comparison-sumo-js": () => import("./../../../src/pages/comparison/sumo.js" /* webpackChunkName: "component---src-pages-comparison-sumo-js" */),
  "component---src-pages-features-email-popups-js": () => import("./../../../src/pages/features/email-popups.js" /* webpackChunkName: "component---src-pages-features-email-popups-js" */),
  "component---src-pages-features-exit-intent-js": () => import("./../../../src/pages/features/exit-intent.js" /* webpackChunkName: "component---src-pages-features-exit-intent-js" */),
  "component---src-pages-features-grow-your-email-list-js": () => import("./../../../src/pages/features/grow-your-email-list.js" /* webpackChunkName: "component---src-pages-features-grow-your-email-list-js" */),
  "component---src-pages-features-increase-ecommerce-sales-js": () => import("./../../../src/pages/features/increase-ecommerce-sales.js" /* webpackChunkName: "component---src-pages-features-increase-ecommerce-sales-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-multiple-popup-triggering-js": () => import("./../../../src/pages/features/multiple-popup-triggering.js" /* webpackChunkName: "component---src-pages-features-multiple-popup-triggering-js" */),
  "component---src-pages-features-spin-the-wheel-pop-ups-js": () => import("./../../../src/pages/features/spin-the-wheel-pop-ups.js" /* webpackChunkName: "component---src-pages-features-spin-the-wheel-pop-ups-js" */),
  "component---src-pages-index-element-js": () => import("./../../../src/pages/index-element.js" /* webpackChunkName: "component---src-pages-index-element-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-angularjs-js": () => import("./../../../src/pages/integration/angularjs.js" /* webpackChunkName: "component---src-pages-integration-angularjs-js" */),
  "component---src-pages-integration-big-cartel-js": () => import("./../../../src/pages/integration/big-cartel.js" /* webpackChunkName: "component---src-pages-integration-big-cartel-js" */),
  "component---src-pages-integration-bigcommerce-js": () => import("./../../../src/pages/integration/bigcommerce.js" /* webpackChunkName: "component---src-pages-integration-bigcommerce-js" */),
  "component---src-pages-integration-blogger-js": () => import("./../../../src/pages/integration/blogger.js" /* webpackChunkName: "component---src-pages-integration-blogger-js" */),
  "component---src-pages-integration-carrd-js": () => import("./../../../src/pages/integration/carrd.js" /* webpackChunkName: "component---src-pages-integration-carrd-js" */),
  "component---src-pages-integration-clickfunnels-js": () => import("./../../../src/pages/integration/clickfunnels.js" /* webpackChunkName: "component---src-pages-integration-clickfunnels-js" */),
  "component---src-pages-integration-craft-js": () => import("./../../../src/pages/integration/craft.js" /* webpackChunkName: "component---src-pages-integration-craft-js" */),
  "component---src-pages-integration-dnn-software-js": () => import("./../../../src/pages/integration/dnn-software.js" /* webpackChunkName: "component---src-pages-integration-dnn-software-js" */),
  "component---src-pages-integration-drupal-js": () => import("./../../../src/pages/integration/drupal.js" /* webpackChunkName: "component---src-pages-integration-drupal-js" */),
  "component---src-pages-integration-expression-engine-js": () => import("./../../../src/pages/integration/expression-engine.js" /* webpackChunkName: "component---src-pages-integration-expression-engine-js" */),
  "component---src-pages-integration-ghost-js": () => import("./../../../src/pages/integration/ghost.js" /* webpackChunkName: "component---src-pages-integration-ghost-js" */),
  "component---src-pages-integration-google-tag-manager-js": () => import("./../../../src/pages/integration/google-tag-manager.js" /* webpackChunkName: "component---src-pages-integration-google-tag-manager-js" */),
  "component---src-pages-integration-graphcms-js": () => import("./../../../src/pages/integration/graphcms.js" /* webpackChunkName: "component---src-pages-integration-graphcms-js" */),
  "component---src-pages-integration-instapage-js": () => import("./../../../src/pages/integration/instapage.js" /* webpackChunkName: "component---src-pages-integration-instapage-js" */),
  "component---src-pages-integration-jimdo-js": () => import("./../../../src/pages/integration/jimdo.js" /* webpackChunkName: "component---src-pages-integration-jimdo-js" */),
  "component---src-pages-integration-joomla-js": () => import("./../../../src/pages/integration/joomla.js" /* webpackChunkName: "component---src-pages-integration-joomla-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-integration-kirim-js": () => import("./../../../src/pages/integration/kirim.js" /* webpackChunkName: "component---src-pages-integration-kirim-js" */),
  "component---src-pages-integration-magento-js": () => import("./../../../src/pages/integration/magento.js" /* webpackChunkName: "component---src-pages-integration-magento-js" */),
  "component---src-pages-integration-mailchimp-js": () => import("./../../../src/pages/integration/mailchimp.js" /* webpackChunkName: "component---src-pages-integration-mailchimp-js" */),
  "component---src-pages-integration-mailerlite-js": () => import("./../../../src/pages/integration/mailerlite.js" /* webpackChunkName: "component---src-pages-integration-mailerlite-js" */),
  "component---src-pages-integration-netlify-js": () => import("./../../../src/pages/integration/netlify.js" /* webpackChunkName: "component---src-pages-integration-netlify-js" */),
  "component---src-pages-integration-october-cms-js": () => import("./../../../src/pages/integration/october-cms.js" /* webpackChunkName: "component---src-pages-integration-october-cms-js" */),
  "component---src-pages-integration-opencart-js": () => import("./../../../src/pages/integration/opencart.js" /* webpackChunkName: "component---src-pages-integration-opencart-js" */),
  "component---src-pages-integration-prestashop-js": () => import("./../../../src/pages/integration/prestashop.js" /* webpackChunkName: "component---src-pages-integration-prestashop-js" */),
  "component---src-pages-integration-progress-sitefinity-js": () => import("./../../../src/pages/integration/progress-sitefinity.js" /* webpackChunkName: "component---src-pages-integration-progress-sitefinity-js" */),
  "component---src-pages-integration-sendgrid-js": () => import("./../../../src/pages/integration/sendgrid.js" /* webpackChunkName: "component---src-pages-integration-sendgrid-js" */),
  "component---src-pages-integration-sendinblue-js": () => import("./../../../src/pages/integration/sendinblue.js" /* webpackChunkName: "component---src-pages-integration-sendinblue-js" */),
  "component---src-pages-integration-sendloop-js": () => import("./../../../src/pages/integration/sendloop.js" /* webpackChunkName: "component---src-pages-integration-sendloop-js" */),
  "component---src-pages-integration-shopify-js": () => import("./../../../src/pages/integration/shopify.js" /* webpackChunkName: "component---src-pages-integration-shopify-js" */),
  "component---src-pages-integration-silverstripe-js": () => import("./../../../src/pages/integration/silverstripe.js" /* webpackChunkName: "component---src-pages-integration-silverstripe-js" */),
  "component---src-pages-integration-squarespace-js": () => import("./../../../src/pages/integration/squarespace.js" /* webpackChunkName: "component---src-pages-integration-squarespace-js" */),
  "component---src-pages-integration-subrion-js": () => import("./../../../src/pages/integration/subrion.js" /* webpackChunkName: "component---src-pages-integration-subrion-js" */),
  "component---src-pages-integration-textpattern-js": () => import("./../../../src/pages/integration/textpattern.js" /* webpackChunkName: "component---src-pages-integration-textpattern-js" */),
  "component---src-pages-integration-typo-3-js": () => import("./../../../src/pages/integration/typo3.js" /* webpackChunkName: "component---src-pages-integration-typo-3-js" */),
  "component---src-pages-integration-umbraco-js": () => import("./../../../src/pages/integration/umbraco.js" /* webpackChunkName: "component---src-pages-integration-umbraco-js" */),
  "component---src-pages-integration-vercel-js": () => import("./../../../src/pages/integration/vercel.js" /* webpackChunkName: "component---src-pages-integration-vercel-js" */),
  "component---src-pages-integration-visual-composer-js": () => import("./../../../src/pages/integration/visual-composer.js" /* webpackChunkName: "component---src-pages-integration-visual-composer-js" */),
  "component---src-pages-integration-volusion-js": () => import("./../../../src/pages/integration/volusion.js" /* webpackChunkName: "component---src-pages-integration-volusion-js" */),
  "component---src-pages-integration-vuejs-js": () => import("./../../../src/pages/integration/vuejs.js" /* webpackChunkName: "component---src-pages-integration-vuejs-js" */),
  "component---src-pages-integration-webflow-js": () => import("./../../../src/pages/integration/webflow.js" /* webpackChunkName: "component---src-pages-integration-webflow-js" */),
  "component---src-pages-integration-weebly-js": () => import("./../../../src/pages/integration/weebly.js" /* webpackChunkName: "component---src-pages-integration-weebly-js" */),
  "component---src-pages-integration-wix-js": () => import("./../../../src/pages/integration/wix.js" /* webpackChunkName: "component---src-pages-integration-wix-js" */),
  "component---src-pages-integration-woocommerce-js": () => import("./../../../src/pages/integration/woocommerce.js" /* webpackChunkName: "component---src-pages-integration-woocommerce-js" */),
  "component---src-pages-integration-wordpress-js": () => import("./../../../src/pages/integration/wordpress.js" /* webpackChunkName: "component---src-pages-integration-wordpress-js" */),
  "component---src-pages-integration-yii-framework-js": () => import("./../../../src/pages/integration/yii-framework.js" /* webpackChunkName: "component---src-pages-integration-yii-framework-js" */),
  "component---src-pages-kerjamail-js": () => import("./../../../src/pages/kerjamail.js" /* webpackChunkName: "component---src-pages-kerjamail-js" */),
  "component---src-pages-lifetime-deal-js": () => import("./../../../src/pages/lifetime-deal.js" /* webpackChunkName: "component---src-pages-lifetime-deal-js" */),
  "component---src-pages-marketing-material-js": () => import("./../../../src/pages/marketing-material.js" /* webpackChunkName: "component---src-pages-marketing-material-js" */),
  "component---src-pages-partner-program-js": () => import("./../../../src/pages/partner-program.js" /* webpackChunkName: "component---src-pages-partner-program-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tag-js": () => import("./../../../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-wordpress-black-friday-cyber-monday-deals-2020-js": () => import("./../../../src/pages/wordpress-black-friday-cyber-monday-deals-2020.js" /* webpackChunkName: "component---src-pages-wordpress-black-friday-cyber-monday-deals-2020-js" */),
  "component---src-pages-wordpress-black-friday-deals-js": () => import("./../../../src/pages/wordpress-black-friday-deals.js" /* webpackChunkName: "component---src-pages-wordpress-black-friday-deals-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog-tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */)
}

